body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App-background {
  background-image: url('background.jpg');
  background-size: cover;
  background-position: center;
  height: 100vh; /* This sets the height to the full viewport height */
  width: 100vw; /* This sets the width to the full viewport width */
}
.logo{
padding-left: 2rem;
padding-top:2rem;
margin-bottom: 10rem;
}
.content-text{
  color : #07F2F8;
  font-family: sans-serif;
  margin-left : 2rem;
}
.Hero{
  font-family: sans-serif;
  height: 30rem;
  width:  40rem;
  margin-bottom: 3rem;

}
.Hero h4{
  color : white;
  font-family: sans-serif;
  margin-left : 2rem;
  margin-bottom: 3rem;  
  font-size: 1.5rem;


}
.demo-button {
  background-color: #07F2F8; /* Light blue background */
  border: none;
  color: black;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 25px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition-duration: 0.4s;
  position: absolute; /* Positioning the button absolutely */
  top: 1rem;            /* At the top of the container */
  right: 0;          /* At the right of the container */
  margin: 10px;      /* Adding some margin to not stick to the corner */
}

.demo-button:hover {
  background-color: #555; /* Darker background on hover */
}
.modal {
  display: none; /* Hidden by default */
  position: fixed;
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black with opacity */
  padding-top: 60px;
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: 5% auto; /* 5% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
  max-width: 450px; /* Maximum width */
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* Add Zoom Animation */
.modal-content {
  animation-name: zoom;
  animation-duration: 0.6s;
}

@keyframes zoom {
  from {transform: scale(0)} 
  to {transform: scale(1)}
}

/* The success/fail icons and messages */
.icon {
  display: none; /* Hidden by default */
  text-align: center;
  font-size: 24px;
}

.success {
  color: green;
}

.fail {
  color: red;
}
.my-custom-modal .modal-content {
  background-color: #1a1a1a; /* Dark background */
  color: #ffffff; /* Light text */
  padding: 20px; /* Space inside the modal */
}

/* Input Fields */
.my-custom-modal input[type="text"] {
  background-color: transparent; /* No background */
  border: none; /* No border */
  border-bottom: 1px solid #ffffff; /* Only underline */
  margin-bottom: 20px; /* Space between fields */
  color: #ffffff; /* Light text */
  width: 100%; /* Full width */
}

/* Change placeholder text style */
.my-custom-modal input[type="text"]::placeholder {
  text-transform: uppercase; /* Capitalize placeholder text */
}

/* Button */
.my-custom-modal button {
  background-color: #30cfd0; /* Teal background */
  color: #ffffff; /* Light text */
  border: none; /* No border */
  padding: 10px 20px; /* Padding inside button */
  border-radius: 20px; /* Rounded corners */
  cursor: pointer; /* Hand cursor on hover */
}

/* Button Hover Effect */
.my-custom-modal button:hover {
  background-color: #2bb8b1; /* Slightly darker on hover */
}